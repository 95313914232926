@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-bg {
  background-image: url("/src/assets/img/header-bg.jpg");
}

.web-design {
  background-image: url("/src/assets/img/web-design.jpg");
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  filter: grayscale(1);
  min-height: 300px;
}

.web-design1 {
  background-image: url("/src/assets/img/web-design1.jpg");
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  filter: grayscale(1);
  min-height: 300px;
}

